/** @jsx jsx */
import { jsx } from "theme-ui"
import { handleAuthentication } from "../utils/auth"
import { Loading } from "../components"

function Callback() {
  handleAuthentication()
  return <Loading text="Loading" />
}

export default Callback
